export default {
  siteTitle: 'Get bonus',
  blogerName: 'PRANKEDY',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/prankedy'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@SoyEdy'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Prankedy'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/thenotoriousprankedy/'
    }
  ],
  projects: [
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ce7a346c7',
      gameTitle: 'Avalon: The Lost Kingdom (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cf682b791',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Regístrate con mi código promocional <strong>PRANKEDY</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'PRANKEDY',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark'
};
